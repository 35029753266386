import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isModalOpen: false,
};
export var testEditSlice = createSlice({
    name: 'testEdit',
    initialState: initialState,
    reducers: {
        changeSelectedTest: function (state, action) {
            state.selectedTestId = action.payload;
        },
        toggleTestEditModal: function (state) {
            state.isModalOpen = !state.isModalOpen;
        },
    },
});
export var testEditActions = testEditSlice.actions;
export var testEditReducer = testEditSlice.reducer;
