import { createSlice } from '@reduxjs/toolkit';
import { getCalendarEvents } from '../services/getCalendarEvents';
import { updateCalendarEvent } from '../services/updateCalendarEvent';
import { createCalendarEvent } from '../services/createCalendarEvent';
var initialState = {
    events: [],
    isLoading: false,
    error: {},
    modalState: {
        isActive: false,
        currentEvent: {
            id: 1,
            title: '',
            description: '',
            ext: '',
            start_date: '',
            end_date: '',
            time: '',
            for_all: 0,
            event_url: '',
        },
        eventList: [],
        mode: 'show',
    },
};
export var calendalSlice = createSlice({
    name: 'calendar',
    initialState: initialState,
    reducers: {
        setEvents: function (state, _a) {
            var payload = _a.payload;
            var events = payload.data;
            if (events) {
                state.events = events;
            }
        },
        updateEvent: function (state, _a) {
            var payload = _a.payload;
            var index = state.events.findIndex(function (event) { return event.id === payload.id; });
            if (index !== -1) {
                state.events[index] = payload;
            }
        },
        createEvent: function (state, _a) {
            var payload = _a.payload;
            state.events.push(payload);
        },
        toggleModal: function (state) {
            state.modalState.isActive = !state.modalState.isActive;
        },
        setModalMode: function (state, _a) {
            var payload = _a.payload;
            state.modalState.mode = payload;
        },
        setModalCurrentEvent: function (state, _a) {
            var payload = _a.payload;
            state.modalState.currentEvent = payload;
        },
        setModalEventList: function (state, _a) {
            var payload = _a.payload;
            state.modalState.eventList = payload;
        },
    },
    extraReducers: function (builder) {
        builder
            // getCoursesLessons
            .addCase(getCalendarEvents.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getCalendarEvents.fulfilled, function (state, action) {
            state.isLoading = false;
            state.events = action.payload;
        })
            .addCase(getCalendarEvents.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(createCalendarEvent.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        })
            .addCase(createCalendarEvent.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        })
            .addCase(createCalendarEvent.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            // state.events = action.payload;
        })
            .addCase(updateCalendarEvent.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        })
            .addCase(updateCalendarEvent.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        })
            .addCase(updateCalendarEvent.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            // state.data.allEvents = action.payload;
        });
    },
});
export var calendarActions = calendalSlice.actions;
export var calendarReducer = calendalSlice.reducer;
