var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { classNames } from 'shared/lib/classNames';
import cls from './Textarea.module.scss';
export function Textarea(_a) {
    var className = _a.className, label = _a.label, name = _a.name, noteText = _a.noteText, placeholder = _a.placeholder, registerOptions = _a.registerOptions, defaultValue = _a.defaultValue, maxLength = _a.maxLength, required = _a.required, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var control = useFormContext().control;
    return (_jsx(Controller, { name: name, control: control, defaultValue: defaultValue !== null && defaultValue !== void 0 ? defaultValue : '', rules: registerOptions, disabled: disabled, render: function (_a) {
            var _b, _c;
            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
            return (_jsxs("div", { className: classNames(cls.Input, {}, [className]), children: [_jsxs("div", { className: cls.content, children: [_jsx("label", { htmlFor: name, className: cls.name, children: label }), _jsx("div", { className: cls.data, children: _jsx("textarea", __assign({}, field, { className: cls.input, placeholder: placeholder, maxLength: maxLength, required: required })) })] }), _jsx("div", { className: cls.note, children: noteText }), fieldState.error
                        && (_jsx("div", { className: cls.errorMessage, children: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message : 'Заполните поле' }))] }));
        } }));
}
