var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { classNames } from 'shared/lib/classNames';
import InputMask from 'react-input-mask';
import cls from './Input.module.scss';
export function Input(_a) {
    var className = _a.className, label = _a.label, name = _a.name, noteText = _a.noteText, placeholder = _a.placeholder, registerOptions = _a.registerOptions, mask = _a.mask, defaultValue = _a.defaultValue, hidden = _a.hidden, maxLength = _a.maxLength, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var control = useFormContext().control;
    return (_jsx(Controller, { name: name, control: control, disabled: disabled, defaultValue: defaultValue !== null && defaultValue !== void 0 ? defaultValue : '', rules: registerOptions, render: function (_a) {
            var _b;
            var _c, _d, _e;
            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
            return (_jsxs("div", { className: classNames(cls.Input, (_b = {}, _b[cls.hidden] = hidden, _b), [className]), children: [_jsxs("div", { className: cls.content, children: [_jsx("label", { htmlFor: name, className: cls.name, children: label }), _jsx("div", { className: cls.data, children: _jsx(InputMask, __assign({ mask: mask !== null && mask !== void 0 ? mask : '' }, field, { className: cls.input, placeholder: placeholder, value: (_c = field.value) !== null && _c !== void 0 ? _c : '', maxLength: maxLength })) })] }), _jsx("div", { className: cls.note, children: noteText }), fieldState.error
                        && (_jsx("div", { className: cls.errorMessage, children: ((_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message) ? (_e = fieldState.error) === null || _e === void 0 ? void 0 : _e.message : 'Заполните поле' }))] }));
        } }));
}
